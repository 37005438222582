<template>
  <div>
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card chat-app">
          <div id="plist" class="people-list">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <input type="text" class="form-control" placeholder="Buscar..." />
            </div>
            <ul class="list-unstyled chat-list mt-2 mb-0">
              <li
                class="clearfix mt-1"
                :class="userSelected.id == user.id ? 'active' : ''"
                v-for="user in users"
                :key="user.id"
                @click="getMessages(user)"
              >
                <img :src="user.photo" alt="avatar" />
                <div class="about">
                  <div class="name">{{ user.name }}</div>
                  <div class="status">
                    <i class="fa fa-circle online"></i> online
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="chat">
            <div v-if="!userSelected.id">
              <div class="empty middle">
                <div class="container-v">
                  <div class="vertical-center" align="center">
                    <p>Clique sobre uma conversa para visualizar.</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="chat-header clearfix">
                <div class="row">
                  <div class="col-lg-6">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#view_info"
                    >
                      <img :src="userSelected.photo" alt="avatar" />
                    </a>
                    <div class="chat-about">
                      <h6 class="m-b-0">{{ userSelected.name }}</h6>
                      <small>Online</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-history" id="chat-messages">
                <ul class="m-b-0" v-if="userSelected.chat">
                  <li
                    class="clearfix"
                    v-for="message in messages"
                    :key="message.id"
                  >
                    <div
                      class="message-data"
                      :class="
                        user.id == message.user_id ? 'text-right' : 'text-left'
                      "
                    >
                      <span class="message-data-time">{{
                        moment(message.created_at).fromNow()
                      }}</span>
                    </div>
                    <div
                      class="message"
                      :class="
                        user.id == message.user_id
                          ? 'other-message float-right'
                          : 'my-message float-left'
                      "
                    >
                      {{ message.message }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="chat-message clearfix">
                <div class="input-group mb-0">
                  <div
                    class="input-group-prepend"
                    style="cursor: pointer"
                    @click="sendMessage"
                  >
                    <span class="input-group-text"
                      ><i class="fas fa-paper-plane"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mensagem..."
                    v-model="message"
                    v-on:keyup.enter="sendMessage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      users: [],
      userSelected: {},
      message: "",
      messages: [],
      moment: moment,
    };
  },
  components: {},
  methods: {
    getMessages(user) {
      const self = this;
      const api = self.$store.state.api + "chat/get_messages";

      self.userSelected = user;
      self.playSound();
      axios
        .post(api, self.userSelected)
        .then((response) => {
          self.messages = response.data;
          setTimeout(() => {
            $("#chat-messages").scrollTop($("#chat-messages")[0].scrollHeight);
          }, 100);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "chat/users";

      axios
        .post(api)
        .then((response) => {
          self.users = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    sendMessage: function () {
      const self = this;
      const api = self.$store.state.api + "chat/send-message";

      axios
        .post(api, { message: self.message, userSelected: self.userSelected })
        .then((response) => {
          self.getUsers();
          self.message = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    playSound(volume) {
      var audio = new Audio(
        "https://soundbible.com/mp3/Air%20Plane%20Ding-SoundBible.com-496729130.mp3"
      );
      if (volume == null || volume == undefined) {
        volume = 0;
      }
      audio.volume = volume;
      audio.play();
    },
  },
  mounted() {
    const self = this;
    let permission = Notification.requestPermission();

    self.getUsers();

    self.user = self.$store.state.user;

    setTimeout(() => {
      Echo.private("user." + self.user.id).listen(".Alert", (data) => {
        console.log("teste");
        self.messages = data.message;
        setTimeout(() => {
          if (self.user.id != data.from_user_id) {
            const greeting = new Notification("Triagem IT4D", {
              body: "Nova mensagem no chat!",
              icon: "http://triagem.ares-saude.com.br/img/logo.083ed3b8.png",
            });
            self.playSound(0.5);
            self.$message("Chat", "Nova mensagem no chat", "info");
          }
          $("#chat-messages").scrollTop($("#chat-messages")[0].scrollHeight);
        }, 100);
      });
    }, 1000);
  },
  created() {},
};
</script>

<style scoped>
body {
  background-color: #f4f7f6 !important;
  margin-top: 20px !important;
}
.card {
  background: #fff !important;
  transition: 0.5s !important;
  border: 0 !important;
  margin-bottom: 30px !important;
  border-radius: 0.55rem !important;
  position: relative !important;
  width: 100% !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%) !important;
}
.chat-app .people-list {
  width: 350px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  padding: 20px !important;
  z-index: 7;
}

.chat-app .chat {
  margin-left: 350px !important;
  border-left: 1px solid #eaeaea;
}

[dir] .dark-layout .chat .chat-history[data-v-57446385] {
  padding: 20px !important;
  border-bottom: 2px solid #283046;
}

[dir] .dark-layout .people-list .chat-list li.active[data-v-57446385] {
  background: #283046;
}

[dir="ltr"] .dark-layout .col-xl,
[dir="ltr"] .dark-layout .col-xl-auto,
[dir="ltr"] .dark-layout .col-xl-12,
[dir="ltr"] .dark-layout .col-xl-11,
[dir="ltr"] .dark-layout .col-xl-10,
[dir="ltr"] .dark-layout .col-xl-9,
[dir="ltr"] .dark-layout .col-xl-8,
[dir="ltr"] .dark-layout .col-xl-7,
[dir="ltr"] .dark-layout .col-xl-6,
[dir="ltr"] .dark-layout .col-xl-5,
[dir="ltr"] .dark-layout .col-xl-4,
[dir="ltr"] .dark-layout .col-xl-3,
[dir="ltr"] .dark-layout .col-xl-2,
[dir="ltr"] .dark-layout .col-xl-1,
[dir="ltr"] .dark-layout .col-lg,
[dir="ltr"] .dark-layout .col-lg-auto,
[dir="ltr"] .dark-layout .col-lg-12,
[dir="ltr"] .dark-layout .col-lg-11,
[dir="ltr"] .dark-layout .col-lg-10,
[dir="ltr"] .dark-layout .col-lg-9,
[dir="ltr"] .dark-layout .col-lg-8,
[dir="ltr"] .dark-layout .col-lg-7,
[dir="ltr"] .dark-layout .col-lg-6,
[dir="ltr"] .dark-layout .col-lg-5,
[dir="ltr"] .dark-layout .col-lg-4,
[dir="ltr"] .dark-layout .col-lg-3,
[dir="ltr"] .dark-layout .col-lg-2,
[dir="ltr"] .dark-layout .col-lg-1,
[dir="ltr"] .dark-layout .col-md,
[dir="ltr"] .dark-layout .col-md-auto,
[dir="ltr"] .dark-layout .col-md-12,
[dir="ltr"] .dark-layout .col-md-11,
[dir="ltr"] .dark-layout .col-md-10,
[dir="ltr"] .dark-layout .col-md-9,
[dir="ltr"] .dark-layout .col-md-8,
[dir="ltr"] .dark-layout .col-md-7,
[dir="ltr"] .dark-layout .col-md-6,
[dir="ltr"] .dark-layout .col-md-5,
[dir="ltr"] .dark-layout .col-md-4,
[dir="ltr"] .dark-layout .col-md-3,
[dir="ltr"] .dark-layout .col-md-2,
[dir="ltr"] .dark-layout .col-md-1,
[dir="ltr"] .dark-layout .col-sm,
[dir="ltr"] .dark-layout .col-sm-auto,
[dir="ltr"] .dark-layout .col-sm-12,
[dir="ltr"] .dark-layout .col-sm-11,
[dir="ltr"] .dark-layout .col-sm-10,
[dir="ltr"] .dark-layout .col-sm-9,
[dir="ltr"] .dark-layout .col-sm-8,
[dir="ltr"] .dark-layout .col-sm-7,
[dir="ltr"] .dark-layout .col-sm-6,
[dir="ltr"] .dark-layout .col-sm-5,
[dir="ltr"] .dark-layout .col-sm-4,
[dir="ltr"] .dark-layout .col-sm-3,
[dir="ltr"] .dark-layout .col-sm-2,
[dir="ltr"] .dark-layout .col-sm-1,
[dir="ltr"] .dark-layout .col,
[dir="ltr"] .dark-layout .col-auto,
[dir="ltr"] .dark-layout .col-12,
[dir="ltr"] .dark-layout .col-11,
[dir="ltr"] .dark-layout .col-10,
[dir="ltr"] .dark-layout .col-9,
[dir="ltr"] .dark-layout .col-8,
[dir="ltr"] .dark-layout .col-7,
[dir="ltr"] .dark-layout .col-6,
[dir="ltr"] .dark-layout .col-5,
[dir="ltr"] .dark-layout .col-4,
[dir="ltr"] .dark-layout .col-3,
[dir="ltr"] .dark-layout .col-2,
[dir="ltr"] .dark-layout .col-1 {
  padding-right: 1rem;
  padding-left: 1rem;
  background: #283046;
}

[dir] .dark-layout .chat .chat-header[data-v-57446385] {
  padding: 15px 20px !important;
  border-bottom: 2px solid #283046;
}

[dir] .dark-layout .chat .chat-history .other-message[data-v-57446385] {
  background: white !important;
}

.dark-layout .chat .chat-history .message-data-time[data-v-57446385] {
  color: white !important;
}

.people-list {
  -moz-transition: 0.5s !important;
  -o-transition: 0.5s !important;
  -webkit-transition: 0.5s !important;
  transition: 0.5s;
}

.people-list .chat-list li {
  padding: 10px 15px !important;
  list-style: none !important;
  border-radius: 3px;
}

.people-list .chat-list li:hover {
  background: #efefef !important;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 15px;
}

.dark-layout .people-list .chat-list li:hover {
  background: #161d31 !important;
  cursor: pointer;
}

.dark-layout .people-list .chat-list li.active {
  background: #161d31 !important;
}

.dark-layout .people-list .chat-list li .name {
  font-size: 15px;
}

.people-list .chat-list img {
  width: 45px !important;
  border-radius: 50%;
}

.people-list img {
  float: left !important;
  border-radius: 50%;
}

.people-list .about {
  float: left !important;
  padding-left: 8px;
}

.people-list .status {
  color: #999 !important;
  font-size: 13px;
}

.chat .chat-header {
  padding: 15px 20px !important;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left !important;
  border-radius: 40px !important;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left !important;
  padding-left: 10px;
}

.chat .empty {
  min-height: 80vh;
  padding: 10px;
}

.chat .chat-history {
  padding: 20px !important;
  border-bottom: 2px solid #fff;
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none !important;
  margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px !important;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #434651 !important;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: #444 !important;
  padding: 18px 20px !important;
  line-height: 26px !important;
  font-size: 16px !important;
  border-radius: 7px !important;
  display: inline-block !important;
  position: relative;
}

.chat .chat-history .message:after {
  bottom: 100% !important;
  left: 7% !important;
  border: solid transparent !important;
  content: " " !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  pointer-events: none !important;
  border-bottom-color: #fff !important;
  border-width: 10px !important;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #efefef;
  min-width: 15%;
}

.chat .chat-history .my-message:after {
  bottom: 100% !important;
  left: 30px !important;
  border: solid transparent !important;
  content: " " !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  pointer-events: none !important;
  border-bottom-color: #efefef !important;
  border-width: 10px !important;
  margin-left: -10px;
}

.chat .chat-history .other-message {
  background: #e8f1f3 !important;
  text-align: right;
  min-width: 15%;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3 !important;
  left: 80% !important;
}

.chat .chat-message {
  padding: 20px;
}

.online,
.offline,
.me {
  margin-right: 2px !important;
  font-size: 8px !important;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden !important;
  display: block !important;
  font-size: 0 !important;
  content: " " !important;
  clear: both !important;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .chat-app .people-list {
    height: 465px !important;
    width: 100% !important;
    overflow-x: auto !important;
    background: #fff !important;
    left: -400px !important;
    display: none;
  }
  .chat-app .people-list.open {
    left: 0;
  }
  .chat-app .chat {
    margin: 0;
  }
  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }
  .chat-app .chat-history {
    height: 300px !important;
    overflow-x: auto;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1992px) {
  .chat-app .chat-list {
    height: 650px !important;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: 600px !important;
    overflow-x: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px !important;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: calc(100vh - 350px) !important;
    overflow-x: auto;
  }
} */

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 1380px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .card {
    zoom: 80%;
  }
} */

@media only screen and (max-device-width: 1380px) {
  .chat-app .chat-list {
    max-height: 550px !important;
    overflow-x: auto;
  }
  .container-v {
    height: calc(100vh - 45px) !important;
    position: relative;
    text-align: center !important;
  }
  .chat-app .chat-history {
    height: calc(100vh - 175px) !important;
    overflow-x: auto;
  }
}

@media only screen and (min-device-width: 1380px) {
  .chat-app .chat-list {
    max-height: 650px !important;
    overflow-x: auto;
  }
  .container-v {
    height: calc(100vh - 215px) !important;
    position: relative;
    text-align: center !important;
  }
  .chat-app .chat-history {
    height: calc(100vh - 340px) !important;
    overflow-x: auto;
  }
}

.chat-list::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.chat-list::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}

.chat-list::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(255, 255, 255); /* creates padding around scroll thumb */
}

.people-list .chat-list li .name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
}

/* @media only screen and (min-device-width: 1380px) and (orientation: landscape) {
  .dark-layout .chat-app .chat-list {
    height: 100% !important;
    overflow-x: auto;
    background: #283046;
  }
  .dark-layout .container-v {
    height: calc(100vh - 215px) !important;
    position: relative;
    text-align: center !important;
    background: #283046;
  }
  .dark-layout .chat-app .chat-history {
    height: calc(100vh - 350px) !important;
    overflow-x: auto;
    background: #283046;
  }
} */

[dir] .dark-layout .card[data-v-57446385] {
  background: #283046 !important;
  border: 0 !important;
  margin-bottom: 30px !important;
  border-radius: 0.55rem !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%) !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center !important;
}
</style>
